import swal from 'sweetalert'
import Constants from "./constants";

export default {
    SUCCESS: "success",
    WARN: "warning",
    ERROR: "error",

    alert: function (title, text, fnCallback) {
        swal({
            title: title,
            text: text,
            timer: 5000,
            icon: this.WARN
        })
            .then(() => {
                if (fnCallback) {
                    fnCallback();
                }
                swal.close();
            });
    },

    success: function (texto, fnCallback) {
        swal({
            title: "ÉXITO",
            text: texto,
            timer: 5000,
            icon: this.SUCCESS
        })
            .then(()  => {
                if (fnCallback && typeof fnCallback === 'function') {
                    fnCallback();
                }
                swal.close();
            });
    },

    error: function (text, fnCallback) {
        swal({
            title: "ERROR",
            text: text && (typeof text === 'string' || text instanceof String) ? text : Constants.Errors.SERVER_UNAVAILABLE,
            timer: 5000,
            icon: this.ERROR
        })
            .then(()  => {
                if (fnCallback && typeof fnCallback === 'function') {
                    fnCallback();
                }
                swal.close();
            });
    },

    dialog: function (titulo, texto, fnOK, fnCancel) {
        swal({
            title: titulo ? titulo : "Confirmación",
            text: texto,
            icon: "warning",
            buttons: {
                cancelar: {text: "Cancelar", value: "cancel"},
                ok: {text: "Acepto", value: "ok"}
            },
            dangerMode: true
        })
            .then(value => {
                switch (value) {
                    case "ok":
                        swal.close();
                        if (fnOK) {
                            setTimeout(fnOK, 300);
                        }
                        break;
                    case "cancel":
                        setTimeout(function () {
                            if (fnCancel) {
                                fnCancel();
                            }
                        }, 300);
                        break;
                }
            });
    }
}
