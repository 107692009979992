<template>
  <b-button
    type="none"
    class="refresh-button bg-transparent p-0 border-0 position-relative"
    @mouseover="showTooltip = 'refresh'"
    @mouseleave="showTooltip = null"
    @click="$emit('click')"
  >
    <img
      src="@/core/assets/images/components/refresh-icon.svg"
      alt="Actualizar"
    />

    <custom-tooltip :text="text" v-show="showTooltip" />
  </b-button>
</template>

<script>
import {CustomTooltip} from "@/core/components";

export default {
  name: "RefreshTableButton",
  data() {
    return {
      showTooltip: false
    }
  },
  props: {
    text: String
  },
  components: {
    CustomTooltip
  }
};
</script>

<style>
</style>
