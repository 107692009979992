<template>
    <div v-if="show" class="container">
      <div class="d-flex justify-content-between container-options">
        <div class=" d-flex flex-column options options-left separator-right" >
          <span  v-for="(option) in options" :class="`d-flex justify-content-between item ${ option.key === selectedTypeFilter.key? 'active' : ''}`" v-bind:key="option.label" @click="changeSelectedType(option)">{{option.label}} <div class="icon-active"></div></span>
        </div>

        <div class=" d-flex flex-column options options-right" v-if="selectedTypeFilter.type === 1">
              <span v-for="subItem in selectedTypeFilter.options"
                :class="`d-flex justify-content-between sub-item ${ filters[selectedTypeFilter.key] !== subItem.key ? '': 'active'}`" v-bind:key="subItem.label"
                @click="setOptionFilter(selectedTypeFilter.key, subItem)">{{subItem.label}}</span>
        </div>
        <div class=" d-flex flex-column options options-right" v-if="selectedTypeFilter.type === 2">

        <b-form-checkbox-group
                        id="multipleOption"
                        class="custom-form"
                        v-model="filters[selectedTypeFilter.key]">
          <span v-for="subItem in selectedTypeFilter.options" class="d-flex justify-content-between sub-item" v-bind:key="subItem.label" >
            <b-form-checkbox :value="subItem.key" ><span class="custom-checkbox-label-filters">{{subItem.label}}</span></b-form-checkbox>
          </span>
        </b-form-checkbox-group>
      </div>

<!--      <div class=" d-flex flex-column options options-right options-calendar" v-if="selectedTypeFilter.type === 3">
        <FilterDate :filter="{key:'123hola'}" :filters="filters" :selectedTypeFilter="selectedTypeFilter" @selectDate="selectDate" />

      </div>-->

      </div>

    <div class="d-flex justify-content-end
          align-items-center container-buttons">
      <span class="mx-2 button button-cancel" @click="hideFilter">Cancelar</span>
      <button class="mx-2 button button-primary" @click="setFilters">Aplicar</button>
    </div>
    </div>

</template>

<script>

export default {
  name: 'FilterMenu',
  data() {
     return {
      selectedTypeFilter :  this.options[0],
      filters: {...this.selectedOptionFilter},
      selectedDate: null,
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedOptionFilter: {
      type: Object,
    }
  },
  methods: {
    hideFilter () {
       this.$emit('hide')
    },
    selectDate (range, option) {

      this.filters[option] = {
        startDate: range.startDate,
        endDate: range.endDate
      }
    },
    setFilters () {
      for (const filter in this.filters) {

        if (Array.isArray(this.filters[filter]) && this.filters[filter].length === 0 ){
          delete this.filters[filter]
        }
        else if(typeof this.filters[filter] === 'object' && !Array.isArray(this.filters[filter]) && this.filters[filter].startDate === ""){
          delete this.filters[filter]
        }

      }
        this.$emit('setFilters', { ...this.filters })
    },
    changeSelectedType(option) {
      this.selectedTypeFilter = option
    },
    setOptionFilter (option, subItem) {
       if (!this.filters[option] || this.filters[option] !== subItem.key) {
          this.filters = {
            ...this.filters,
            [option]: subItem.key
          }
      }
      else {
        delete this.filters[option];
        this.filters = {
          ...this.filters
        }
      }
    },
  },
  watch: {
    selectedOptionFilter: function () {
      this.filters = {...this.selectedOptionFilter}
    },
  },
  created() {
    this.options.forEach((e)=> {
      if(e.type === 2){
        this.filters = {
          ...this.filters,
          [e.key] : []
        }
      } else if (e.type === 3) {

        this.filters = {
          ...this.filters,
          [e.key] : {
            startDate: '',
            endDate: ''
          }
        }
      }

    })
  }

}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/components/filter-menu.styl"

</style>
