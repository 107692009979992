function getErrorMessages(response) {
    const errorMessages = []
    if(response.errors){
        if(Array.isArray(response.errors) && response.errors.length > 0){
            response.errors.forEach(e => {
                if (e.description) {
                    errorMessages.push(e.description)
                }
            })
        } else {
            for (const prop in response.errors) {
                if (Object.prototype.hasOwnProperty.call(response.errors, prop)) {
                    errorMessages.push(response.errors[prop]);
                }
            }
        }
    } else {
        errorMessages.push('Ocurrió un error inesperado, por favor intente nuevamente')
    }
    return errorMessages.join(" \n ");
}


export default {
    getErrorMessages
}
