<template>
  <!-- <b-button >Toggle Sidebar</b-button> -->
  <aside :class="`sidebar ${isMenuOpen ? '' : 'closeSidebar'}`">
    <section class="d-flex mt-1 mb-0 sidebar-item-title pl-0" @click="toggleMenu">
      <div class="logo-icon"></div>
      <span class="sidebar-title w-50">PHOENIX</span>
    </section>
    <section>
      <ul class="pl-0">
        <router-link :to="{ name: 'dashboard'}" tag="li"
                     exact-active-class="active"
                     class="d-flex sidebar-item" @mouseover.native="tooltipType = 'home-item'"
                     @mouseleave.native="tooltipType = null">
          <div class="d-flex align-items-center">
            <div class="sidebar-item-icon home-icon"></div>
            <span class="sidebar-item-text">Portada</span>
            <CustomTooltip custom-class="tooltip-sidebar" v-if="!isMenuOpen && tooltipType === 'home-item'"
                           text="Portada"/>
          </div>
        </router-link>
        <router-link event="" tag="li"
                     active-class="active"
                     to="/config"
                     class="sidebar-item d-flex align-items-center"
                     @click.native.prevent="currentSubmenu === 'config' ? (setCurrentSubmenu(null)) : (setCurrentSubmenu('config'))">
          <div class="d-flex flex-column justify-content-center sidebar-item-container">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="d-flex" @mouseover="tooltipType = 'config-item'"
                   @mouseleave="tooltipType = null">
                <div class="sidebar-item-icon pencil-icon"></div>
                <span class="sidebar-item-text">Mantenimiento</span>
                <CustomTooltip custom-class="tooltip-sidebar" v-if="!isMenuOpen && tooltipType === 'config-item'"
                               text="Mantenimiento"
                />
              </div>
            </div>
            <span :class="`sidebar-item-description ${isCurrent('config') ? 'active' : ''}`">
              Series, Relación entre productos
            </span>
          </div>
          <div>
            <div :class="`arrow-icon mr-4 mt-1 ${isCurrent('config') ? 'active' : ''}`"></div>
          </div>
        </router-link>
        <section>
          <ul :class="`sidebar-submenu ${isCurrent('config') ? 'active' : ''} pl-0`">
            <sub-item routeName="config-series-list" text="Series"></sub-item>
            <sub-item routeName="config-relation-list" text="Relación entre productos"></sub-item>
            <sub-item routeName="config-data-deletion" text="Borrado de información"></sub-item>
          </ul>
        </section>
        <router-link event="" tag="li"
                     active-class="active"
                     to="/daily"
                     class="sidebar-item d-flex align-items-center"
                     @click.native.prevent="currentSubmenu === 'daily' ? (setCurrentSubmenu(null)) : (setCurrentSubmenu('daily'))">
          <div class="d-flex flex-column justify-content-center sidebar-item-container">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="d-flex" @mouseover="tooltipType = 'daily-item'"
                   @mouseleave="tooltipType = null">
                <div class="sidebar-item-icon daily-icon"></div>
                <span class="sidebar-item-text">Control diario</span>
                <CustomTooltip custom-class="tooltip-sidebar" v-if="!isMenuOpen && tooltipType === 'daily-item'"
                               text="Control diario"
                />
              </div>
            </div>
            <span :class="`sidebar-item-description ${isCurrent('daily') ? 'active' : ''}`">
              Carga inv. final, Carga diaria, Validar compras/costo, Validar conversiones, Procesar libro, Descargar libro
            </span>
          </div>
          <div>
            <div :class="`arrow-icon mr-4 mt-1 ${isCurrent('daily') ? 'active' : ''}`"></div>
          </div>
        </router-link>
        <section>
          <ul :class="`sidebar-submenu ${isCurrent('daily') ? 'active' : ''} pl-0`">
            <sub-item routeName="daily-final-batch-list" text="Carga inv. final"></sub-item>
            <sub-item routeName="daily-voucher-batch-list" text="Carga diaria"></sub-item>
            <sub-item routeName="daily-purchases-list" text="Validar compras/costo"></sub-item>
            <sub-item routeName="daily-conversion-sum-list" text="Validar conversiones"></sub-item>
            <sub-item routeName="daily-process-list" text="Procesar libro"></sub-item>
            <sub-item routeName="daily-inventory-download" text="Descargar libro"></sub-item>
            <sub-item routeName="daily-custom-voucher-list" text="Guías externas"></sub-item>
            <sub-item routeName="daily-voucher-list" text="Guías internas"></sub-item>
            <sub-item routeName="update-daily-voucher-cost" text="Actualización de costos"></sub-item>
            <sub-item routeName="list-daily-voucher-cost-difference" text="Listar diferencia de costos"></sub-item>
          </ul>
        </section>
      </ul>
    </section>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CustomTooltip  from "./CustomToolTip"
import SubItem  from "./sidebar/SubItem"

export default {
  name: "PageSidebar",
  components: {CustomTooltip, SubItem},
  data() {
    return {
      showSideBar: true,
      categories: [],
      subcategories: [],
      tooltipType: null
    };
  },
  methods: {
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      toggleMenu: "app/toggleMenu",
    }),
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
    }),
  },
  mounted: function () {},
};
</script>

<style scoped lang="stylus">
@import '../styles/pageSidebar.styl';
</style>
