<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Series</h1>
    </div>
    <div class="table-container">
      <div class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        ">
        <div class="d-flex align-items-center justify-content-between ml-2 ">
          <div class="d-flex align-items-center justify-content-between ml-2 filter" @click="showFilters = !showFilters">
            <div class="icon-filter"></div>
            <div>Filtrar Series</div>
          </div>

        </div>
        <div class="d-flex flex-wrap align-items-center w-100  ml-2 ">
          <span v-for="(val, name) in filters" v-bind:key="name"
            class="d-flex justify-content-between ml-2 filter-secondary"> {{ getFilterName(name, val) }} <div
              class="icon-delete ml-3" @click="deleteFilter(name)"></div></span>
        </div>
        <filter-menu :show="showFilters" @hide="showFilters = false" :options="filterOptions"
          :selectedOptionFilter="filters" @setFilters="setFilters"></filter-menu>
        <section class="d-flex align-items-center">
          <DownloadTableData text="Descargar" class="mr-10p" />
          <SearchBar placeholder="Buscar por código o descripción" @search="onSearch" class="mr-10p" />
          <ButtonAdd :route="{ name: 'config-serie-create' }" text="Agregar serie" />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Tipo de movimiento</th>
              <th>Estado</th>
              <th class="pr-md-4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.code }}</td>
              <td class="pl-5">{{ o.description }}</td>
              <td class="text-center">{{ getType(o.type).label }}</td>
              <td class="text-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="text-center d_option pr-md-4">
                <Dropdown>
                  <template slot="dropdown-content">
                    <DropdownItem @click="$router.push({ name: 'config-serie-edit', params: { serieId: o.id } })"
                      text="Editar" />
                    <DropdownItem :text="getStatus(o.status).action" @click="openChangeStatus(o)" />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination :total="total" :page="page" :page-size="pageSize" :pages="pages" :changePage="changePage"
          :changePageSize="changePageSize" />
      </div>
    </div>
    <ConfirmationModal :show="confirmationModal.show" @hide="closeConfirmationModal" :data="confirmationModal"
      @confirm="confirmModal" />
  </div>
</template>

<script>
import { VoucherSerieService } from "@/core/services";
import { Alert, Constants, Util } from "@/core/utils";
import { ButtonAdd, ConfirmationModal, DownloadTableData, FilterMenu, SearchBar, Dropdown, DropdownItem } from "@/core/components";
import { ConfirmationModalMixin, PaginationMixin, FilterMixin } from "@/core/mixins";

export default {
  components: {
    ButtonAdd,
    SearchBar,
    DownloadTableData,
    FilterMenu,
    ConfirmationModal,
    Dropdown,
    DropdownItem,
  },
  mixins: [PaginationMixin, ConfirmationModalMixin, FilterMixin],
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null,
      filterOptions: [
        {
          label: "Tipo de movimiento",
          key: "type",
          type: Constants.TypeFilters.SINGLE,
          options: [Constants.VoucherSerieType.INCOME, Constants.VoucherSerieType.OUTCOME],
        },
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [Constants.VoucherSerieStatus.ACTIVE, Constants.VoucherSerieStatus.INACTIVE],
        }],
    };
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = { page: this.page - 1., size: this.pageSize, sort: 'code,asc', search: this.search, ...this.filters };
        const response = await VoucherSerieService.list(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getType(key) {
      return Util.searchValue(key, Constants.VoucherSerieType);
    },
    getStatus(key) {
      return Util.searchValue(key, Constants.VoucherSerieStatus);
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === 'delete')
        this.deleteSerie();
      else if (this.confirmationModal.action === 'update')
        this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await VoucherSerieService.toggleStatus(this.selectedOption.id))
          .payload;

        const serie = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (serie) {
          const index = this.list.indexOf(serie);
          this.$set(this.list, index, resp);
        }
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);

      }
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado de la serie?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    openDelete(o) {
      this.selectedOption = o;
      this.openConfirmationModal({ acceptText: "Borrar", action: "delete" });
    },
    async deleteSerie() {
      try {
        await this.$store.dispatch("app/loading", true);
        const resp = (await VoucherSerieService.delete(this.selectedOption.id)).payload;

        const serie = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (serie) {
          const index = this.list.indexOf(serie);
          this.list.splice(index, 1);
          this.total--;
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped></style>



