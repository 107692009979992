<template>
  <div>
    <section class="login">
      <LoginHeader />
      <main class="d-flex justify-content-center align-items-center">
        <div
          class="
            container
            d-flex
            justify-content-center
            align-items-center
            d-md-block
            justify-content-md-start
            align-items-md-start
          "
        >
          <div class="row justify-content-center">
            <section
              class="
                col-11 col-md-6 col-lg-5
                order-1 order-md-0
                text-center text-md-left
                pt-5
                animate__animated animate__fadeIn
              "
            >
              <h1 class="title font-weight-normal">
                Mejora el control de tus inventarios y procesos
              </h1>
              <p class="description text-secondary">
                Sólo debes cargar tu información, procesarla y encontrar las irregularidades.
                Phoenix te ayudará a mejorar tus procesos y operaciones internas.
              </p>

              <section
                class="
                  d-flex
                  flex-column
                  align-items-center align-items-md-start
                  mt-4
                "
              >
                <AccessButton />

                <router-link to="/" class="button-blue mt-3"
                  >Ir a documentación</router-link
                >
              </section>
            </section>

            <section
              class="
                col-md-6 col-lg-7
                order-0 order-md-1
                d-flex
                justify-content-center
                align-items-center
                position-relative
              "
            >
              <img
                src="@/core/assets/images/login/banner.svg"
                alt="Imagen de bienvenida"
                class="img-fluid banner-img animate__animated animate__fadeIn"
              />
              <img
                src="@/core/assets/images/login/rectangle.svg"
                alt="Figura base"
                class="img-fluid rectangle animate__animated animate__fadeIn"
              />
            </section>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>

import {SecurityService} from "@/core/services"
import {ErrorUtils, SSOUtils} from '@/core/utils'
import LoginHeader from "../Components/Header";
import AccessButton from "../Components/AccessButton";

export default {
  name: "LoginPage",
  components: {
    AccessButton,
    LoginHeader,
  },
  data() {
    return {
      error: false,
      errorMsg: null,
    };
  },
  methods: {
    loginSso() {
      this.errorMsg = null;
      this.loading = true;
      this.$store.commit('app/setLoading', true)
      const callbackParams = SSOUtils.getCallbackParameters(location.href);
      if (this.$store.getters["auth/getSsoState"] === callbackParams.state) {
        this.$store.commit("auth/ssoState", null);
        SecurityService.loginSSO(callbackParams)
          .then(async (response) => {
            if (response.status !== "OK") {
              return (this.errorMsg = ErrorUtils.getErrorMessages(response));
            }
            await this.$store.dispatch("auth/setAuthInfo", response.payload);
            await this.$store.dispatch("app/loadStartupData");
            await this.$router.push({ name: "businesses" });
          })
          .catch((err) => {
            console.error(err);
            // this.errorMsg = ErrorUtils.getErrorMessages(err);
          })
          .finally(() => (this.$store.commit('app/setLoading', false)));
      } else {
        this.errorMsg =
          "El estado SSO no coincide, por favor intenta nuevamente";
      }
    },
  },
  mounted() {
    if (window.location.search) this.loginSso();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/login-page';
</style>



