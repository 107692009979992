<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Guías externas</h1>
    </div>

    <div class="table-container">
      <div class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        ">

        <div class="d-flex align-items-center justify-content-between ml-2">

        </div>

        <section class="d-flex align-items-center">
          <DownloadTableData text="Descargar" class="mr-10p" @click="download" />
          <SearchBar placeholder="Buscar por producto, descripción, serie, número, creado por" @search="onSearch"
            class="mr-10p" />
          <ButtonAdd :route="{ name: 'daily-custom-voucher-create' }" size="small" text="Crear" />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table w-100">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Serie</th>
              <th>Número</th>
              <th>Producto</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Unidad</th>
              <th>Costo</th>
              <th>Creado por</th>
              <th>Actualizado por</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">

              <td class="text-center" v-local-date="o.date"></td>
              <td class="text-center">{{ o.serie }}</td>
              <td class="text-center">{{ o.number }}</td>
              <td class="text-center">{{ o.productId }}</td>
              <td>{{ o.productDesc }}</td>
              <td class="text-center" v-decimal="o.quantity"></td>
              <td class="text-center">{{ o.unit }}</td>
              <td class="text-center" v-decimal:pen="o.cost"></td>
              <td class="text-center">{{ o.createdBy }}</td>
              <td class="text-center">{{ o.updatedBy }}</td>
              <td class="text-center d_option pr-md-4">
                <Dropdown>
                  <template slot="dropdown-content">
                    <DropdownItem text="Editar"
                      @click="$router.push({ name: 'daily-custom-voucher-edit', params: { voucherId: o.id } })" />
                    <DropdownItem text="Eliminar" @click="openDelete(o)" />
                  </template>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination :total="total" :page="page" :page-size="pageSize" :pages="pages" :changePage="changePage"
          :changePageSize="changePageSize" />
      </div>
    </div>
    <ConfirmationModal :show="confirmationModal.show" @hide="closeConfirmationModal" :data="confirmationModal"
      @confirm="deleteVoucher" />
  </div>
</template>

<script>
import { DownloadService, VoucherService } from "@/core/services";
import { Alert, Util } from '@/core/utils'
import { ConfirmationModalMixin, PaginationMixin } from '@/core/mixins'
import { mapGetters } from "vuex";
import { ButtonAdd, ConfirmationModal, DownloadTableData, SearchBar, Dropdown, DropdownItem } from "@/core/components";
import fileDownload from "js-file-download";

export default {
  components: {
    ButtonAdd,
    SearchBar,
    DownloadTableData,
    ConfirmationModal,
    Dropdown,
    DropdownItem
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      selectedOption: null
    }
  },
  mixins: [PaginationMixin, ConfirmationModalMixin],
  computed: {
    ...mapGetters(
      {
        lastClosedDate: 'app/getLastClosedDate'
      },
    ),
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch('app/loading', true);
        const params = { page: this.page - 1., size: this.pageSize, sort: 'date,desc', search: this.search, custom: true };
        const response = await VoucherService.listDaily(params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },

    async deleteVoucher() {
      try {
        this.closeConfirmationModal();
        await this.$store.dispatch('app/loading', true);
        const resp = (await VoucherService.deleteCustomDaily(this.selectedOption.id)).payload;

        const batch = this.list.find((o) => {
          return o.id === resp.id;
        });
        if (batch) {
          const index = this.list.indexOf(batch);
          this.list.splice(index, 1);
        }
        Alert.success("Guía externa eliminada correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    canModify() {
      return true // moment(this.lastClosedDate).diff(batchDate) < 0; TODO undo
    },
    openDelete(o) {
      this.selectedOption = o
      this.openConfirmationModal({ acceptText: "Borrar" });
    },
    async download() {
      if (this.list.length === 0) return;
      try {
        await this.$store.dispatch('app/loading', true);
        const params = { search: this.search };
        const response = await DownloadService.dailyCustomVouchers(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
  },
  async mounted() {
    await this.loadData();
  }
}
</script>

<style lang="stylus" scoped>

</style>



