<template>
  <nav class="col-12 navbar nav-header py-0">
    <div class="col-12 d-flex align-items-center justify-content-between">

      <b-dropdown v-if="business"  :text="business.name" menu-class="custom-dropdown-menu" class="custom-dropdown" left>
          <b-dropdown-item class="dropdown-title" @click="$router.push({name: 'businesses'})">Ver todas las empresas</b-dropdown-item>
          <b-dropdown-item v-for="i in businesses" :key="i.id" @click="changeBusiness(i)"
                           v-show="i.id !== business.id">{{ i.name }}
          </b-dropdown-item>
        </b-dropdown>
      <UserDropdown />
      </div>
  </nav>
</template>
<script>
import {mapGetters} from 'vuex'
import UserDropdown from '@/core/components/UserDropdown'

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: []
    }
  },
  components:{
    UserDropdown
  },
  computed: {
    ...mapGetters(
        {
          user: 'auth/getUser',
          business: 'app/getBusiness',
          businesses: 'app/getBusinesses'
        },
    ),
  },
  methods: {

    async changeBusiness(business) {
      this.$store.commit('app/business', business);
      await this.$store.dispatch('app/loadAvailableDate');
      await this.$store.dispatch('app/loadStartDate');
      await this.$store.dispatch('app/loadLastClosedDate');

      if (this.$route.name !== 'dashboard')
        this.$router.push({name: 'dashboard'})
    }
  }
  ,
  mounted: function () {
  }
}
</script>

<style scoped lang="stylus">
@import "../styles/pageHeader.styl"

</style>
