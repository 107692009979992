<template>
  <b-modal v-model="showLocal" @hidden="hide" centered no-close-on-esc no-close-on-backdrop hide-footer hide-header
           content-class="warning-modal">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between">
        <div class="modal-title-left">{{ data.title }}</div>
        <b-button
          class="icon-close icon-close-default"
          @click="hide" aria-label="Close"
        >
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-4 text-center">
        <div class="my-1 d-flex justify-content-center align-items-center warning-text"><i
          class="icon-warning"></i>{{ data.description }}
        </div>
        <div class="d-flex justify-content-end mt-3">
            <span class="mt-3 mb-2 mx-1 button button-cancel" @click="hide">
              {{ data.cancelText }}
            </span>
          <b-button class="mt-3 mb-2 mx-1 button button-delete" @click="confirm">
            {{ data.acceptText }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {ModalMixin} from "@/core/mixins";

export default {
  name: 'ConfirmationModal',
  mixins: [ModalMixin],
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style lang="stylus" scoped>


</style>
