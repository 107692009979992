import {http} from '@/http'

export default {
  listRelationships(params) {
    return http.get('api/v1/product/relationship', {params})
  },
  getRelationship(id) {
    return http.get(`api/v1/product/relationship/${id}`)
  },
  saveRelationship(payload) {
    return http.post('api/v1/product/relationship', payload)
  },
  updateRelationship(id, payload) {
    return http.put(`api/v1/product/relationship/${id}`, payload)
  },
  deleteRelationship(id) {
    return http.delete(`api/v1/product/relationship/${id}`)
  },
  uploadRelationship(data) {
    return http.post('api/v1/upload/product/relationship', data)
  },
}
