import {LocalStorage} from '@/core/utils'
import {BusinessService} from "@/core/services";

const state = {
  business: LocalStorage.getBusiness() ? JSON.parse(LocalStorage.getBusiness()) : null,
  businesses: [],
  availableDate: null,
  startDate: null,
  lastClosedDate: null,
  menuOpen: true,
  currentSubmenu: localStorage.getItem('currentSubmenu') === null ? [] : localStorage.getItem('currentSubmenu').split(','),
  loading: false
};

const getters = {
  getAvailableDate: state => {
    return state.availableDate
  },
  getStartDate: state => {
    return state.startDate;
  },
  getLastClosedDate: state => {
    return state.lastClosedDate;
  },
  getBusiness: state => {
    return state.business;
  },
  getBusinesses: state => {
    return state.businesses;
  },
  getCurrentSubmenu: state => state.currentSubmenu,
  isMenuOpen : state=> state.menuOpen,
  isLoading: state => state.loading
};

const actions = {
  loading: (store, val) => {
    store.commit('setLoading', val)
  },
  loadStartupData: ({dispatch, getters}) => {
    return new Promise((resolve, reject) => {
      if (getters.getBusinesses && getters.getBusinesses.length > 0) {
        resolve();
        return;
      }

      dispatch('loadBusinesses').then(() => {
        Promise.all([dispatch('loadAvailableDate'), dispatch('loadLastClosedDate'), dispatch('loadStartDate'),]).then(() => {
          resolve()
        }).catch((error) => {
          console.error(error);
          reject(error);
        });
      })

    });
  },
  loadAvailableDate: ({commit}) => {
    return new Promise((resolve, reject) => {
      BusinessService.getAvailableDate().then(
        response => {
          if (response.payload)
            commit('availableDate', response.payload)
          else
            commit('availableDate', {})
          resolve()
        }
      ).catch((error) => {
        console.error(error);
        reject(error);
      })
    });
  },
  loadLastClosedDate: ({commit}) => {
    return new Promise((resolve, reject) => {
      BusinessService.getLastClosedDate().then(
        response => {
          if (response.payload)
            commit('lastClosedDate', response.payload)
          else
            commit('lastClosedDate', {})
          resolve()
        }
      ).catch((error) => {
        console.error(error);
        reject(error);
      })
    });
  },
  loadStartDate: ({commit}) => {
    return new Promise((resolve, reject) => {
      BusinessService.getStartDate().then(
        response => {
          if (response.payload)
            commit('startDate', response.payload)
          else
            commit('startDate', {})
          resolve()
        }
      ).catch((error) => {
        console.error(error);
        reject(error);
      })
    });
  },
  loadBusinesses: ({commit}) => {
    return new Promise((resolve, reject) => {
      BusinessService.list().then(
        response => {
          if (response.payload)
            commit('businesses', response.payload)
          else
            commit('businesses', {})
          resolve()
        }
      ).catch((error) => {
        console.error(error);
        reject(error);
      })
    });
  }
};

const mutations = {
  availableDate(state, value) {
    state.availableDate = value;
  },
  startDate(state, value) {
    state.startDate = value;
  },
  lastClosedDate(state, value) {
    state.lastClosedDate = value;
  },
  businesses(state, value) {
    if (value && value.length > 0 && !state.business) {
      state.business = value[0];
      LocalStorage.setBusiness(value[0]);
    }

    state.businesses = value;
  },
  business(state, value) {
    value ? LocalStorage.setBusiness(value) : LocalStorage.clearBusiness();
    state.business = value;
  },
  setCurrentSubmenu(state, value) {
      const values = state.currentSubmenu
      state.currentSubmenu = values.includes(value)?  values.filter(e => e !== value) : [...values, value]
      localStorage.setItem('currentSubmenu', state.currentSubmenu)
  },
  toggleMenu(state) {
    if (!window.matchMedia("(max-width: 768px)").matches) {
      state.menuOpen = !state.menuOpen
    }
  },
  setMenu(state, value) {
    state.menuOpen = value
  },
  setLoading(state, value) {
    state.loading = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
