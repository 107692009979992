<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Actualización de costos de guías G15 en base a relación de productos</h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body class="custom-card custom-card--sm">
            <b-card-header header-tag="section" class="
                custom-card-header
                d-flex
                justify-content-between
                align-items-center
              ">
              <h3>Seleccione fecha</h3>
            </b-card-header>

            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save">
                <div class="form-group">
                    <label for="type">Tipo de proceso *</label>
                    <FormSelect
                      id="type"
                      v-model="type"
                      :items="types"
                      defaultOption="Elegir tipo de proceso"
                      :showError="$v.type.$error"
                    />
                    <span
                      class="error"
                      v-if="
                      $v.type.$error &&
                      !$v.type.required
                    "
                    >
                    <img
                      src="@/core/assets/images/components/error-form.svg"
                      alt="Seleccionar"
                      class="error-icon"
                    />
                    Tipo de data es requerido
                  </span>
                  </div>
                <div class="mb-4">
                  <label for="start-date">Fecha inicio</label>
                  <b-input-group>
                    <FormDatepicker label-id="start-date" :show-calendar="isOpenStartPicker"
                      :min="$store.getters['app/getStartDate']" :max="$store.getters['app/getAvailableDate']"
                      :hideHeader="true" @show="isOpenStartPicker = true" @hidden="isOpenStartPicker = false"
                      v-model="startDate" class="w-100" />
                  </b-input-group>
                  <span class="error" v-if="$v.startDate.$error && !$v.startDate.required">Fecha de inicio
                    es requerida</span>
                </div>
                <div class="mb-0">
                  <label for="end-date">Fecha final</label>
                  <b-input-group>
                    <FormDatepicker label-id="end-date" :show-calendar="isOpenEndPicker"
                      :min="$store.getters['app/getStartDate']" :max="$store.getters['app/getAvailableDate']"
                      :hideHeader="true" @show="isOpenEndPicker = true" @hidden="isOpenEndPicker = false"
                      v-model="endDate" class="w-100" />
                  </b-input-group>

                  <span class="error" v-if="$v.endDate.$error && !$v.endDate.required">Fecha final es
                    requerida</span>
                </div>
                <div class="form-group-buttons text-right w-100">
                  <button class="button button-primary" type="submit">Procesar</button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormDatepicker, FormSelect } from '@/core/components';
import { VoucherService } from "@/core/services";
import {Alert, Constants} from "@/core/utils";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      startDate: this.$store.getters["app/getAvailableDate"],
      endDate: this.$store.getters["app/getAvailableDate"],
      isOpenStartPicker: false,
      isOpenEndPicker: false,
      type: null,
      types: [
        Constants.UpdateCostType.COST_G65,
        Constants.UpdateCostType.COST_G15,
      ],
    };
  },
  components: {
    FormDatepicker,
    FormSelect
  },
  validations: {
    startDate: { required },
    endDate: { required },
    type: { required },
    
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      try {
        await VoucherService.updateCosts({
          startDate: this.startDate,
          endDate: this.endDate,
          type: this.type
        });
        Alert.success("Se ha ejecutado el proceso de correctamente");
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>



