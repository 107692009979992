<template>
  <footer :class="`admin-footer ${isDashboard}`">
      <div class="inner-container d-flex justify-content-between align-items-center h-100">
        <span class="text">V{{ version }} {{ year }}</span>
        <p class="text text-dark  my-0">Forjado por Wize</p>
      </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      version: process.env.PACKAGE_VERSION,
      year: new Date().getFullYear()
    };
  },
  computed:{
      isDashboard () {
        return  this.$route.name ==='businesses'? 'dashboard-footer' : ''
      }
  }

};
</script>

<style lang="stylus" scoped>
@import "../styles/components/page-footer";
</style>
