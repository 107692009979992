<template>
  <div class="businesses-banner">
    <div class="container h-100">
      <div class="row h-100 justify-content-between align-items-end">
        <!-- Columna 1 -->
        <div class="col-lg-2 col-1 d-none d-lg-block">
          <img
            src="@/core/assets/images/businesses/banner-1.svg"
            alt="Imagen de banner"
            class="image-1 d-none d-lg-block"
          />
        </div>

        <!-- Columna 2 -->
        <div class="col-lg-2 col-2 d-none d-lg-block position-relative">
          <img
            src="@/core/assets/images/businesses/banner-2.svg"
            alt="Imagen de banner"
            class="image-2 d-none d-lg-block"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminBanner"
};
</script>

<style lang="stylus" scoped>
@import "../Styles/banner";
</style>