<template>
  <span :class="`custom-tooltip text-white ${customClass}`">
      {{ text }}
  </span>
</template>

<script>
export default {
  name: 'CustomTooltip',
    props: {
      text: String,
      customClass: {
      type: String,
      default: ''
    }
    },
}
</script>

<style lang="stylus" scoped>
 @import "~@/core/styles/components/custom-tooltip"
</style>
