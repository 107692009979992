<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar relación entre productos</h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="
                custom-card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <h3>Cargar archivo .XLSX</h3>

              <DownloadTableData text="Descargar plantilla" class="mr-3" @click="downloadTemplate"/>
            </b-card-header>

            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save">
                <div class="d-flex">
                  <b-form-checkbox
                    id="clear-all-check"
                    v-model="clearAll"
                  >
                    <span class="font-weight-medium custom-checkbox-label">Remplazar relación de productos existente</span>
                  </b-form-checkbox>
                </div>
                <div>
                  <file-input
                    @onChange="onChange"
                    idFile="relation-batch-file"
                    :acceptFile="['.xlsx']"
                    ref="fileUpload"
                  />
                  <span class="error" v-if="$v.file.$error && !$v.file.required"
                    >El archivo es requerido</span
                  >
                </div>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'config-relation-list' }"
                    class="button button-cancel mr-2"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Procesar
                  </button>
                </div>
                <div class="grupo_campos mt-3">
                  <b
                    ><span class="error" v-if="errors.length > 0"
                      >Errores: {{ errors.length }}</span
                    ></b
                  >
                  <span
                    v-for="(e, i) in errors"
                    class="error"
                    :key="`${i}-error`"
                    >{{ e }}</span
                  >
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductService, DownloadService } from "@/core/services";
import { FileInput } from "@/core/components";
import { Util, Alert } from "@/core/utils";
import { required } from "vuelidate/lib/validators";
import fileDownload from "js-file-download";
import DownloadTableData from "@/core/components/table/DownloadTableData";

export default {
  components: { FileInput, DownloadTableData },
  data() {
    return {
      file: null,
      clearAll: false,
      errors: [],
    };
  },
  validations: {
    file: { required },
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
        clearAll: this.clearAll,
      });
      try {
        const payload = (await ProductService.uploadRelationship(data)).payload;
        if (!payload.success && payload.messages) {
          this.errors = payload.messages;
          return;
        }
        Alert.success("Se ha realizado la carga correctamente");
        this.$router.push({ name: "config-relation-list" });
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.productRelationshipTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {},
};
</script>

<style lang="stylus" scoped></style>



