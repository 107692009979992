<template>
  <b-modal v-model="showLocal" @hidden="hide" dialog-class="container-description" centered no-close-on-esc
           no-close-on-backdrop hide-footer hide-header content-class="modal-description">

    <div class="d-flex justify-content-between aling-items-center modal-header-detail pl-4 pr-4">
      <div class="modal-title-left-primary my-auto px-2">Guías de conversión</div>
      <b-button
        class="icon-close icon-close-primary my-auto"
        @click="hide" aria-label="Close"
      >
      </b-button>
    </div>

    <div class="modal-body-detail">
      <div class="modal-table-container modal-table-container-separator mt-2">
        <div class="modal-title-left-dark mt-3 ml-1 mb-2">Guías de ingreso</div>
        <table class="custom-table w-100">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Serie</th>
            <th>Número</th>
            <th>Producto</th>
            <th>Descripción</th>
            <th>Cantidad</th>
          </tr>
          </thead>
          <tr v-for="i in list.filter((el) => el.income)" :key="i.id">
            <td class="text-center" v-local-date="i.date"></td>
            <td class="text-center">{{ i.serie }}</td>
            <td class="text-center">{{ i.number }}</td>
            <td class="text-center">{{ i.productId }}</td>
            <td>{{ i.productDesc }}</td>
            <td class="text-right" v-decimal="i.quantity"></td>
          </tr>
        </table>
      </div>

      <div class="modal-table-container mt-3">

        <div class="modal-title-left-dark ml-1 mb-2">Guías de salida</div>
        <div>
          <table class="custom-table w-100">
            <thead>
            <tr>
              <th>Fecha</th>
              <th>Serie</th>
              <th>Número</th>
              <th>Producto</th>
              <th>Descripción</th>
              <th>Cantidad</th>
            </tr>
            </thead>
            <tr v-for="i in list.filter((el) => !el.income)" :key="i.id">
              <td class="text-center" v-local-date="i.date"></td>
              <td class="text-center">{{ i.serie }}</td>
              <td class="text-center">{{ i.number }}</td>
              <td class="text-center">{{ i.productId }}</td>
              <td>{{ i.productDesc }}</td>
              <td class="text-right" v-decimal="i.quantity"></td>
            </tr>
          </table>
        </div>
      </div>


      <div class="d-flex justify-content-end pr-5">
            <span class="button button-cancel" @click="hide">
              Cerrar
            </span>
      </div>
    </div>
  </b-modal>
</template>


<script>
import {ModalMixin} from "@/core/mixins";

export default {
  name: 'ConversionsModal',
  mixins: [ModalMixin],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }

}
</script>
<style lang="styl" scoped>

</style>
