<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar inventario final</h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="
                custom-card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <h3>Carga de archivo .xlsx</h3>

              <DownloadTableData
                text="Descargar plantilla"
                @click="downloadTemplate"
              />
            </b-card-header>

            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save">
                <div class="d-flex flex-column flex-md-row align-items-md-center w-50">

                  <label for="input-date" class="pt-1 mr-2">Fecha</label>
                  <b-input-group>
                    <FormDatepicker
                      label-id="input-date"
                      :show-calendar="showDatePicker"
                      :min="$store.getters['app/getStartDate']"
                      :max="$store.getters['app/getAvailableDate']"
                      :hideHeader="false"
                      :error="$v.selectedDate.$error"
                      @show="showDatePicker = true"
                      @hidden="showDatePicker = false"
                      v-model="selectedDate"
                      class="w-100"
                    />
                  </b-input-group>
                  <span
                    class="error"
                    v-if="$v.selectedDate.$error && !$v.selectedDate.required"
                  >Fecha de carga es requerida</span
                  >
                </div>
                <div class="form-group">
                  <file-input
                    @onChange="onChange"
                    idFile="final-batch-file"
                    :acceptFile="['.xlsx']"
                    ref="fileUpload"
                  />
                </div>
                <div class="form-group-buttons">
                  <router-link
                    :to="{ name: 'daily-final-batch-list' }"
                    class="button button-cancel mr-2"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3">
                  <span class="error" v-if="$v.file.$error && !$v.file.required"
                  >El archivo es requerido</span
                  >
                  <b
                  ><span class="error" v-if="errors.length > 0"
                  >Errores: {{ errors.length }}</span
                  ></b
                  >
                  <span
                    v-for="(e, i) in errors"
                    class="error"
                    :key="`${i}-error`"
                  >{{ e }}</span
                  >
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FinalBatchService, DownloadService} from "@/core/services";
import {FileInput} from "@/core/components";
import {Util, Alert} from "@/core/utils";
import {required} from "vuelidate/lib/validators";
import fileDownload from "js-file-download";
import DownloadTableData from "@/core/components/table/DownloadTableData";
import {FormDatepicker} from '@/core/components'

export default {
  components: {FileInput, DownloadTableData, FormDatepicker},
  data() {
    return {
      file: null,
      selectedDate: this.$store.getters["app/getAvailableDate"],
      errors: [],
      showDatePicker: false
    };
  },
  validations: {
    file: {required},
    selectedDate: {required},
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      const data = Util.getFormData({
        file: this.file,
        date: this.selectedDate,
      });
      try {
        const payload = (await FinalBatchService.uploadDaily(data)).payload;
        if (!payload.success && payload.messages) {
          this.errors = payload.messages;
          return;
        }
        Alert.success("Se ha realizado la carga correctamente");
        this.$router.push({name: "daily-final-batch-list"});
      } catch (ex) {
        console.error(ex);
        if (ex.errors && ex.errors.message)
          Alert.error(ex.errors.message);
        else
          Alert.error('Ocurrió un error al procesar el archivo, revise que esté cargando el archivo correcto y pruebe nuevamente');
      } finally {
        this.$refs.fileUpload.remove();
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await DownloadService.dailyFinalBatchTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>



