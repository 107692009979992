import {http} from '@/http'

export default {
  listActive(payload) {
    return http.get('api/v1/vouchertype/active', payload)
  },
  listActiveHasExternalVouchers(payload) {
    return http.get('api/v1/vouchertype/active/hasexternalvoucher', payload)
  },
}
