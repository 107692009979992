import { http } from '@/http'

export default {
  getDailyVoucher(voucherId) {
    return http.get(`api/v1/voucher/daily/${voucherId}`)
  },
  saveCustomDaily(payload) {
    return http.post('api/v1/voucher/daily/custom', payload)
  },
  updateCustomDaily(voucherId, payload) {
    return http.put(`api/v1/voucher/daily/custom/${voucherId}`, payload)
  },
  updateDailyVoucherCost(voucherId, payload) {
    return http.put(`api/v1/voucher/daily/${voucherId}`, payload)
  },
  deleteCustomDaily(voucherId) {
    return http.delete(`api/v1/voucher/daily/custom/${voucherId}`)
  },
  listDaily(params) {
    return http.get('api/v1/voucher/daily', { params })
  },
  updateCosts(params) {
    return http.patch('api/v1/voucher/daily/cost', null, { params })
  },
  lisDailyVoucherCostDifference(params) {
    return http.get('api/v1/voucher/daily/cost/difference', { params })
  },
}
