<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        Editar costo guía de interna
      </h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body style="max-width: 640px" class="custom-card">
            <b-card-header header-tag="section" class="custom-card-header d-flex align-items-center">
              <h3>
                Datos de la guía interna
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-group d-flex flex-column">
                  <label for="input-date">Fecha</label>
                  <FormDatepicker label-id="input-date" :show-calendar="false" :hideHeader="false" :disabled=true
                    v-model="voucher.date" class="w-100 datepicker-container--form-input" />
                </div>
                <div class="form-group">
                  <label for="number">Número de comprobante</label>
                  <input id="number" type="text" maxlength="200" v-model.trim="voucher.number" autocomplete="off" disabled
                    class="custom-form-control" />
                </div>
                <div class="form-group">
                  <label for="document">Documento (GC, GM)</label>
                  <input id="document" type="text" maxlength="200" v-model.trim="voucher.document" autocomplete="off"
                    disabled class="custom-form-control" />
                </div>
                <div class="form-group">
                  <label for="productId">Código de producto</label>
                  <input id="productId" type="text" maxlength="20" v-model.trim="voucher.productId" autocomplete="off"
                    disabled class="custom-form-control" />
                </div>
                <div class="form-group">
                  <label for="description">Descripción</label>
                  <input id="description" type="text" maxlength="200" v-model.trim="voucher.productDesc" disabled
                    autocomplete="off" class="custom-form-control" />
                </div>
                <div class="form-group">
                  <label for="quantity">Cantidad</label>
                  <input id="quantity" type="text" maxlength="20" v-model.trim="voucher.quantity" autocomplete="off"
                    disabled class="custom-form-control" />
                </div>
                <div class="form-group">
                  <label for="cost">Costo *</label>
                  <input id="cost" type="text" maxlength="20" v-model.trim="voucher.cost" autocomplete="off"
                    placeholder="Ingresar costo" class="custom-form-control" :class="{
                      'custom-form-control-error':
                        ($v.voucher.cost.$error && !$v.voucher.cost.required) ||
                        ($v.voucher.cost.$error && !$v.voucher.cost.decimal) ||
                        ($v.voucher.cost.$error && !$v.voucher.cost.minValue),
                    }" />
                  <form-error message="Costo es requerido" v-if="$v.voucher.cost.$error && !$v.voucher.cost.required
                    "></form-error>

                  <form-error message="El costo debe ser un valor numérico" v-if="$v.voucher.cost.$error && !$v.voucher.cost.decimal
                    "></form-error>

                  <form-error message="El costo no puede ser un valor menor a 0" v-if="$v.voucher.cost.$error && !$v.voucher.cost.minValue
                    "></form-error>
                </div>
                <div class="form-group-buttons text-right">
                  <router-link :to="{ name: 'daily-voucher-list' }" class="button button-cancel mr-1">
                    Volver
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Guardar
                  </button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormDatepicker, FormError } from "@/core/components";
import { VoucherService, } from "@/core/services";
import { Alert } from "@/core/utils";
import { decimal, minValue, required } from "vuelidate/lib/validators";

export default {
  components: { FormError, FormDatepicker },
  data() {
    return {
      voucher: {
        date: null,
        document: null,
        number: null,
        productId: null,
        productDesc: null,
        quantity: null,
        cost: null,
      },
      loaded: false,
    };
  },
  validations: {
    voucher: {
      cost: { required, decimal, minValue: minValue(0) },
    },
  },
  watch: {
    "$route.params.voucherId": function () {
      if (this.$route.params.voucherId) this.load(this.$route.params.voucherId);
    }
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      const data = { ...this.voucher };

      try {
        await VoucherService.updateDailyVoucherCost(
          this.$route.params.voucherId,
          data
        );
        Alert.success("Se almacenó la información correctamente");

      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      }
    },
    async load(voucherId) {
      try {
        this.$store.dispatch('app/loading', true);
        const response = await VoucherService.getDailyVoucher(voucherId);
        const {
          date,
          document,
          number,
          productId,
          productDesc,
          quantity,
          cost,
        } = response.payload;
        this.voucher.date = date;
        this.voucher.document = document;
        this.voucher.number = number;
        this.voucher.productId = productId;
        this.voucher.productDesc = productDesc;
        this.voucher.quantity = quantity;
        this.voucher.cost = cost;
        this.loaded = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('app/loading', false);
      }

    },
  },
  async created() {
    this.load(this.$route.params.voucherId);
  },
};
</script>

<style lang="stylus" scoped></style>



