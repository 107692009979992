<template>
    <ul class="filtro_ul filtro_radio">
        <li v-for="i in filter.items" :key="`filteradio-${i}`">
            <input :id="`${filter.key}-${i.key}`" type="radio" :name="filter.key" :value="i"
                   v-model="value">
            <label :for="`${filter.key}-${i.key}`">
                {{i.value}}
            </label>
        </li>
    </ul>
</template>

<script>


    export default {
        props: ['filter', 'filters'],
        data() {
            return {
                value: null,
            }
        },
        watch: {
            value: function () {
                this.$emit("change", this.filter, this.value);
            },
            filters: function () {
                if (!this.filters || this.filters.length <= 0) {
                    this.value = null;
                }
            }
        },
        methods: {},
        mounted: function () {

        }
    }
</script>
