<template>
  <div class="businesses-list container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-9">
        <div class="pb-5 pb-md-0">
          <div class="container">
            <div class="row">
              <div class="col">
                <h3 class="title text-white mb-4">Todas las empresas</h3>
              </div>
            </div>
            <div class="row">
              <!-- TODO hiding add business button for now -->
              <!--  <div class="col-md-4 px-md-2">
                <b-button
                  class="
                    card-add
                    d-block
                    border-0
                    animate__animated animate__fadeIn
                    bg-white
                  "
                >
                  <div
                    class="
                      h-100
                      d-flex
                      justify-content-center
                      align-items-center
                      text-center
                    "
                  >
                    <div class="add-img">
                      <img
                        src="@/core/assets/images/businesses/plus.svg"
                        alt="Icono para agregar empresa"
                        class="d-inline-block"
                      />

                      <h3 class="card-text">
                        Agregar empresa
                      </h3>
                    </div>
                  </div>
                </b-button>
              </div>-->
              <!-- Card empresa -->
              <div
                class="col-md-4 px-md-2"
                v-for="(business, key) in businesses"
                :key="key"
              >
                <BusinessCard :business="business" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessCard from "./BusinessCard";
import {mapGetters} from "vuex";

export default {
  name: "BusinessesList",

  data() {
    return {
    };
  },
  components: {
    BusinessCard,
  },
  computed: {
    ...mapGetters(
        {
          businesses: 'app/getBusinesses'
        },
    ),
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/businesses-list';
</style>
