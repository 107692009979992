import {http} from '@/http'

export default {
  uploadDaily(data) {
    return http.post('api/v1/upload/voucherbatch/daily', data)
  },
  listDaily(params) {
    return http.get('api/v1/voucherbatch/daily', {params})
  },
  deleteDaily(dailyId) {
    return http.delete(`api/v1/voucherbatch/daily/${dailyId}`)
  },
}
