<template>
  <div class="datepicker-container">
    <b-form-datepicker
      :id="labelId"
      :date-format-options="{
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }"
      placeholder="DD/MM/YYYY"
      right
      :max="max"
      :min="min"
      :disabled="disabled"
      locale="es"
      :class="`mb-2 custom-datepicker w-100 ${
        showCalendar ? 'custom-datepicker-focus' : ''
      } ${error ? 'custom-form-control-error' : ''}`"
      v-model="inputVal"
      @show="$emit('show')"
      @hidden="$emit('hidden')"
      label-no-date-selected="Sin fecha seleccionada"
    >
      <template slot="button-content">
        <img src="@/core/assets/images/components/icon-date.svg" alt="Selecciona fecha"/>
      </template>
    </b-form-datepicker>
  </div>
</template>

<script>
export default {
    data() {
        return {
            date: null
        }
    },
    props: {
        labelId: String,
        showCalendar: Boolean,
        hideHeader: Boolean,
        error: Boolean,
        disabled: Boolean,
        value: [Object, String],
        min: {
          type: [Date, String],
          default: null
        },
        max: {
          type: [Date, String],
          default: null
        }
    },
    mounted() {
      this.date = this.defaultValue
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/datepicker";
</style>
