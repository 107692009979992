<template>
  <section
    class="
      file-input
      d-flex
      flex-column flex-md-row
      justify-content-between
      align-items-center"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="file-drop d-flex align-items-center p-3">
      <p class="file-message my-0">{{ file ? file.name : 'Suelta tu archivo aquí...' }}</p>
    </div>

    <div class="file-button-container text-center">
      <section>
        <div style="height: 0px; overflow: hidden">
          <input
            type="file"
            :id="idFile"
            @change="onChange"
            ref="file"
            :accept="acceptFile"
          />
        </div>
        <button type="button" @click="choseFile()" class="file-button">
          Buscar archivo
        </button>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    idFile: {
      type: String,
      required: true,
    },
    acceptFile: {
      type: Array,
      required: true,
    },
    asset: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    onChange() {
      this.file = this.$refs.file.files[0];
      this.$emit("onChange", this.file);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {},
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    remove() {
      this.$refs.file.value = null;
    },
    choseFile() {
      this.$refs.file.click();
    },
  },
  async created() {},
};
</script>

<style lang="stylus" scoped></style>



