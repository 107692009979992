import BusinessesView from "../BusinessesView.vue";
import BusinessesPage from "../Pages/BusinessesPage.vue";

const routes = [
  {
    path: '/businesses',
    component: BusinessesView,
    children: [
      {
        path: '',
        name: 'businesses',
        component: BusinessesPage,
      },
    ]
  }
]

export default routes
