<template>
    <div class="tfiltros_col lg left mOrder2 wrap">
        <div class="tfiltros_select mdi mdi-filter-variant">
            {{label}}
        </div>

        <div class="flex">
            <div class="filtrosCtn flex" style="width:auto">

                <div class="filtros_box flex">
                    <div class="filtros1 flex">
                        <ul class="filtro_ul">
                            <li v-for="(f, i) in data" :key="`filter-${i}`">
                                <a :data-click="`filter-${i}`" class="filtro_tipo mdi mdi-chevron-right" >{{f.label}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="filtros2 flex">
                        <div class="filtro_submenu" :data-type="`filter-${i}`" v-for="(f, i) in data" :key="`filtersub-${i}`">
                            <div class="subfiltro_titulo cerrar_submenu">
                                <i class="filtro_cerrar_icon mdi mdi-arrow-left"></i>
                                {{f.label}}
                            </div>
                            <filter-radio v-if="f.type === 'single'" @change="onChange" :filter="f" :filters="filters"/>
                            <filter-checkbox v-if="f.type === 'multiple'" @change="onChange" :filter="f"
                                             :filters="filters"/>
<!--                            <filter-date v-if="f.type === 'date'" @change="onChange" :filter="f" :filters="filters"/>-->
                        </div>
                    </div>
                </div>
                <div class="filtros_botones flex">
                    <button class="btns btn2" @click="close">Cancelar</button>
                    <button class="btns" @click="applyFilter">Aplicar</button>
                </div>
            </div>
        </div>

        <div class="fAplic" v-for="(f, i) in appliedFilters" :key="`fapl-${i}`">{{getFilterStr(f)}} <i @click="removeFilter(i)"
                                                                                    class="mdi mdi-close-circle"></i>
        </div>
    </div>
</template>

<script>
    import FilterRadio from './FilterRadio.vue'
    import FilterCheckbox from './FilterCheckbox.vue'

    export default {
        props: ['data', 'label'],
        components: {FilterRadio, FilterCheckbox},
        data() {
            return {
                filters: [],
                appliedFilters: [],
            }
        },
        methods: {
            applyFilter() {
                const self = this;
                const data = [];
                self.appliedFilters = [];
                self.filters.forEach(i => {
                    const filt = {key: i.filter.key, data: i.data};
                    self.appliedFilters.push(i);
                    data.push(filt);
                });
                self.$emit("onFilter", data);
              // eslint-disable-next-line no-undef
                $('.filtrosCtn').removeClass('active');
                self.filters = [];
            },
            onChange(filter, data) {
                const self = this;
                const sFilter = self.filters.find(it => it.filter.key === filter.key);
                if (sFilter) {
                    const index = self.filters.indexOf(sFilter);
                    if (data)
                        self.$set(self.filters, index, {filter, data});
                    else
                        self.filters.splice(index, 1);
                } else {
                    if (data)
                        self.filters.push({filter, data});
                }
            },
            close() {
              // eslint-disable-next-line no-undef
                $('.filtrosCtn').removeClass('active');
                this.filters = [];
            },
            getFilterStr(item) {
                let str = item.filter.label + ' =';
                if (item.data instanceof Array && item.data.length > 0) {
                    const first = item.data[0];
                    str += ` "${first.value}"`;
                    if (item.data.length > 1)
                        str += ` y ${item.data.length} más`
                } else {
                    if (item.data.startDate) {
                        str += ` ${item.data.startDate.format('DD/MM/YYYY')}`;
                    } else {
                        str += ` "${item.data.value}"`;
                    }
                    if (item.data.endDate) {
                        str += ` a ${item.data.endDate.format('DD/MM/YYYY')}`;
                    }
                }
                return str
            },
            removeFilter(idx) {
                const self = this;
                const data = [];

                self.appliedFilters.splice(idx, 1);
                self.appliedFilters.forEach(i => {
                    const filt = {key: i.filter.key, data: i.data};
                    data.push(filt);
                });
                self.$emit("onFilter", data);
            }
        },
        mounted: function () {

        }
    }
</script>
