<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Descargar libro</h1>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            class="custom-card custom-card--sm"
          >
            <b-card-header
              header-tag="section"
              class="
                custom-card-header
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <h3>Seleccione fecha</h3>
            </b-card-header>

            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save">
                <div class="mb-4">
                  <label for="start-date">Fecha inicio</label>
                  <b-input-group >
                    <FormDatepicker
                      label-id="start-date"
                      :show-calendar="isOpenStartPicker"
                      :min="$store.getters['app/getStartDate']"
                      :max="$store.getters['app/getAvailableDate']"
                      :hideHeader="true"
                      @show="isOpenStartPicker = true"
                      @hidden="isOpenStartPicker = false"
                      v-model="startDate"
                      class="w-100"
                    />
                  </b-input-group>
                  <span
                    class="error"
                    v-if="$v.startDate.$error && !$v.startDate.required"
                    >Fecha de inicio es requerida</span
                  >
                </div>
                <div class="mb-0">
                  <label for="end-date">Fecha final</label>
                  <b-input-group >
                    <FormDatepicker
                      label-id="end-date"
                      :show-calendar="isOpenEndPicker"
                      :min="$store.getters['app/getStartDate']"
                      :max="$store.getters['app/getAvailableDate']"
                      :hideHeader="true"
                      @show="isOpenEndPicker = true"
                      @hidden="isOpenEndPicker = false"
                      v-model="endDate"
                      class="w-100"
                    />
                  </b-input-group>

                  <span
                    class="error"
                    v-if="$v.endDate.$error && !$v.endDate.required"
                    >Fecha final es requerida</span
                  >
                </div>
                <div class="form-group-buttons text-right w-100">
                  <button class="button button-primary" type="submit">Descargar</button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DownloadService } from "@/core/services";
import { Alert, Util } from "@/core/utils";
import { required } from "vuelidate/lib/validators";
import fileDownload from "js-file-download";
import { FormDatepicker } from '@/core/components'

export default {
  data() {
    return {
      startDate: this.$store.getters["app/getAvailableDate"],
      endDate: this.$store.getters["app/getAvailableDate"],
      isOpenStartPicker: false,
      isOpenEndPicker: false,

    };
  },
  components: {
    FormDatepicker
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    onChange(file) {
      this.file = file;
    },
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      await this.$store.dispatch("app/loading", true);
      try {
        const response = await DownloadService.dailyInventoryFiles({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.$store.dispatch("app/loadAvailableDate");
  },
};
</script>

<style lang="stylus" scoped></style>



