<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Validar compras/costo</h1>
    </div>

    <div class="d-flex w-100">
      <div class="container-filter mr-4">
        <div class="container-header">
          <p class="header-title">Seleccione fecha</p>
        </div>
        <div class="container-body">
          <form @submit.prevent="loadData">
            <label for="input-date" class="body-label">Fecha</label>
            <b-input-group>
              <FormDatepicker
                label-id="input-date"
                :show-calendar="showDatePicker"
                :min="$store.getters['app/getStartDate']"
                :max="$store.getters['app/getAvailableDate']"
                :hideHeader="true"
                :defaultValue="selectedDate"
                :error="$v.selectedDate.$error"
                @show="showDatePicker = true"
                @hidden="showDatePicker = false"
                v-model="selectedDate"
                class="w-100 mx-2"
              />
            </b-input-group>
            <span class="error"
                  v-if="$v.selectedDate.$error && !$v.selectedDate.required">Seleccione una fecha</span>
            <div class="d-flex flex-row-reverse pt-1">
              <button class="btn button-add text-white d-flex justify-content-center align-items-center btn-none small  mb-2"
                      type="submit">Validar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="table-container w-100">
        <div
          class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-between
          align-items-center
        "
        >

          <div class="d-flex align-items-center justify-content-between ml-2">

          </div>
          <div class="d-flex align-items-center justify-content-between ml-2">

          </div>
          <section class="d-flex align-items-center">
            <DownloadTableData text="Descargar" class="mr-10p" @click="download"/>
            <SearchBar
              placeholder="Buscar por código, descripción o factura"
              class="mr-10p"
              @search="onSearch"
            />
          </section>
        </div>
        <div class="mb-0">
          <table class="custom-table w-100">
            <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Factura</th>
              <th>Costo</th>
              <th>Costo anterior</th>
              <th class="text-center d_option pr-md-4">Ratio</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ o.productId }}</td>
              <td>{{ o.productDesc }}</td>
              <td class="text-center">{{ o.voucherSerieNumber }}</td>
              <td class="text-center" v-decimal:pen="o.cost"></td>
              <td class="text-center" v-decimal:pen="o.lastCost"></td>
              <td :class="{'font-weight-bold': o.costFactor !== 1}" class="text-center pr-md-4" v-decimal="o.costFactor"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer d-flex justify-content-end align-items-center">
        </div>
      </div>


    </div>
  </div>

</template>

<script>
import {DownloadService, PurchaseService} from "@/core/services";
import {Alert, Util} from '@/core/utils'
import {required} from "vuelidate/lib/validators";
import fileDownload from 'js-file-download';
import {DownloadTableData, SearchBar, FormDatepicker} from "@/core/components";

export default {
  components: {SearchBar, DownloadTableData, FormDatepicker},
  data() {
    return {
      list: [],
      selectedDate: this.$store.getters['app/getAvailableDate'],
      keyword: null,
      showDatePicker: false,
      search: null,
    }
  },
  validations: {
    selectedDate: {required},
  },
  methods: {
    async loadData() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      try {
        await this.$store.dispatch('app/loading', true);
        const params = {date: this.selectedDate, sort: 'productId', search: this.search};
        if (this.keyword)
          params.keyword = this.keyword;
        const response = await PurchaseService.listDaily(params);
        this.list = response.payload;
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    async download() {
      this.$v.$touch();
      if (this.$v.$error || this.list.length === 0) return;
      try {
        await this.$store.dispatch('app/loading', true);
        const params = {date: this.selectedDate, sort: 'productId', search: this.search};
        const response = await DownloadService.dailyPurchases(params);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error)
        Alert.error(error);
      } finally {
        await this.$store.dispatch('app/loading', false);
      }
    },
    onSearch(keyword) {
      this.search = keyword;
      this.loadData();
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>



